<script setup lang="ts">
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  import { Icon } from '@iconify/vue'
  import { computed } from 'vue'
  import { fmt, randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    modelValue?: string
    type?: string
    label?: string
    placeholder?: string
    required?: boolean
    error?: string[]
    maxLength?: number
    rows?: number
    disabled?: boolean
    readonly?: boolean
    autocomplete?: string
    class?: string
    inputmode?:
      | 'search'
      | 'text'
      | 'none'
      | 'tel'
      | 'url'
      | 'email'
      | 'numeric'
      | 'decimal'
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val ?? '')
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div class="flex flex-col gap-1">
    <label v-if="label" :for="inputId" class="form-label">
      {{ label }}
      <span v-if="required" class="text-red-600">*</span>
    </label>
    <div class="relative flex items-center border-b border-primary-3">
      <slot></slot>
      <textarea
        v-if="type === 'textarea'"
        :id="inputId"
        class="form-input content__text"
        :readonly="readonly"
        :disabled="disabled"
        :value="value"
        :placeholder="placeholder"
        :rows="rows ?? 3"
        :required="required"
        :maxlength="maxLength"
        :class="props.class"
        @input="onInput"
      ></textarea>
      <input
        v-else
        :id="inputId"
        :readonly="readonly"
        class="form-input content__text"
        :disabled="disabled"
        :autocomplete="autocomplete ?? 'off'"
        :type="type"
        :value="value"
        :placeholder="placeholder"
        :required="required"
        :inputmode="inputmode"
        :maxlength="maxLength"
        :class="props.class"
        @input="onInput"
      />
      <div
        v-if="maxLength !== undefined"
        class="text-3xs absolute bottom-1 right-2 bg-white text-gray-500"
      >
        {{ fmt.number(maxLength - (value?.length ?? 0)) }}
      </div>
    </div>
    <app-error-msg :message="error"></app-error-msg>
  </div>
</template>
