export default {
  getFirstLetter: (name: string) => {
    if (typeof name !== 'string' || name.trim() === '') {
      return ''
    }
    return name.trim().charAt(0).toUpperCase()
  },

  firstName: (name: string) => {
    return name.split(' ')[0]
  },

  restOfName: (name: string) => {
    return name.split(' ').slice(1).join(' ')
  },
}
