<script setup lang="ts">
  import { computed } from 'vue'
  import { randomId } from '@/functions'

  const props = defineProps<{
    id?: string
    modelValue?: string
    label?: string
    confirmation?: boolean
  }>()

  const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void
  }>()

  const value = computed({
    set: (val) => {
      emit('update:modelValue', val ?? '')
    },
    get: () => props.modelValue,
  })

  const onInput = (event: Event) => {
    const eventTarget = event.target as HTMLInputElement
    emit('update:modelValue', eventTarget.value)
  }

  const defaultId = randomId()
  const inputId = computed(() => props.id ?? defaultId)
</script>

<template>
  <div class="flex w-fit cursor-pointer items-center gap-2">
    <div
      class="flex h-3 w-3 items-center justify-center rounded-full border border-primary-3 bg-primary-5"
    >
      <input
        :id="inputId"
        class="h-[4px] w-[4px] cursor-pointer rounded-full bg-primary-3 text-primary-3"
        :class="{
          'bg-primary-3 text-primary-3': confirmation === true,
          'bg-primary-5': confirmation === false,
        }"
        type="button"
        :value="value"
        @input="onInput"
      />
    </div>
    <label
      v-if="label"
      :for="inputId"
      class="content__text w-fit cursor-pointer"
    >
      {{ label }}
    </label>
  </div>
</template>
