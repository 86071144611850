import type { App } from 'vue'
import AppAnimatedDot from './app-animated-dot.vue'
import AppButton from './app-button.vue'
import AppErrorMsg from './app-error-msg.vue'
import AppInputCheckbox from './app-input-checkbox.vue'
import AppInput from './app-input.vue'

/**
 * Here you can register components
 * that will be available to all
 * components in the project.
 */
export default {
  install(app: App) {
    app.component('AppAnimatedDot', AppAnimatedDot)
    app.component('AppButton', AppButton)
    app.component('AppInput', AppInput)
    app.component('AppErrorMsg', AppErrorMsg)
    app.component('AppInputCheckbox', AppInputCheckbox)
  },
}

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    AppAnimatedDot: typeof AppAnimatedDot
    AppButton: typeof AppButton
    AppErrorMsg: typeof AppErrorMsg
    AppInput: typeof AppInput
    AppInputCheckbox: typeof AppInputCheckbox
  }
}
